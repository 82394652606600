import React from 'react';
import './css/App.scss';
import CenteredContainer from "./components/Typewriting/CenteredContainer";
import Typewriter from "./components/Typewriting/Typewrite";
import {Login} from "./components/Login/Login";

function App() {
  return (
      <React.StrictMode>
        <CenteredContainer>
          <Typewriter/>
            <Login></Login>
        </CenteredContainer>
      </React.StrictMode>
  );
}

export default App;
