import React, { useState, useEffect } from 'react';
import './typewrite.css'
interface TypewriterProps {
    text?: string;
    delay?: number;
}

const Typewriter: React.FC<TypewriterProps> = ({}) => {

    return (
        <div className="wrapper">
            <h1>Bu yerda e-tob bor</h1>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
        </div>
    );
};

export default Typewriter;
