import React from 'react';
import './typewrite.css'
import {Login} from "../Login/Login";

const CenteredContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className={"CenteredContainer"} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        // background: 'linear-gradient(180deg, #04fafd, 5%, #119dff, 50%, #030423)',
        overflow: 'hidden'
    }}>
        <Login></Login>
        <div className="wrapper">
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
            <div><span className="dot"></span></div>
        </div>
    </div>
);

export default CenteredContainer;
