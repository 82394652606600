import React from 'react';
import {Box, Button, Container, Grid, TextField} from '@mui/material';
import "./login.css"

export const Login = () => {
    return (
        <Container className={"loginContainer"} component="main" maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 2 }}>
                <Grid container spacing={2}>

                    <Grid textAlign={"center"} item xs={12}>
                        <h2>Sign in</h2>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Username"
                            name="username"
                            autoComplete="username"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                        />
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <TextField*/}
                    {/*        required*/}
                    {/*        fullWidth*/}
                    {/*        label="Email"*/}
                    {/*        type="email"*/}
                    {/*        name="email"*/}
                    {/*        autoComplete="email"*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} textAlign={"center"}>
                        <Button
                            type="submit"
                            // fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, bgcolor: 'primary' }}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
